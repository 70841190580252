@tailwind base;
@tailwind components;
@tailwind utilities;


@responsive {
.text-shadow {
	text-shadow: 4px 4px 4px rgba(80,79,79,0.38);
	}

.bg-img-hero {
	background-position:center;
	background-repeat: none;
	},

.cover {
	background-size: cover;
	}
}